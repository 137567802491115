import { Component, OnInit } from '@angular/core';
import { AppState } from 'src/app/_state';
import { Store } from '@ngrx/store';

@Component({
  selector: 'liga-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  stateSubscription: any;
  state: any;

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    this.getState();
  }

  getState() {
    this.stateSubscription = this.store.select((state: any) => {
    }).subscribe();
  }

}
