import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/_state';
import { UsuarioService } from 'src/app/services/usuario-service/usuario.service';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/data-service/data.service';
import { GlobalService } from 'src/app/services/global-service/global.service';
import { fillCurrentUser } from 'src/app/_state/state.actions';

@Component({
  selector: 'liga-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {
  state: any;

  uploadPhoto: FormData;
  photoName: string;

  constructor(
    private store: Store<AppState>,
    private usuarioService: UsuarioService,
    private dataService: DataService,
    private toastr: ToastrService,
    public global: GlobalService
  ) { }

  public credentials: Partial<{ oldpassword: string, newpassword: string, againpassword: string }> = {}
  public isSaving: boolean = false

  ngOnInit(): void {
    this.getState();
  }

  callInput() {
    let input: HTMLElement;
    input = document.querySelector('.upload-picture');
    input.click();
  }

  prepareUpload(event) {
    this.uploadPhoto = new FormData();
    this.uploadPhoto.append('foto', event.target.files[0], event.target.files[0].name);
    this.photoName = event.target.files[0].name;
  }

  async uploadPicture() {
    if (this.uploadPhoto) {
      this.dataService.changePicture(this.uploadPhoto).then((data: any) => {
        this.store.dispatch(fillCurrentUser({payload: data.usuario}));
        this.toastr.success("Sua foto foi alterada com sucesso!", "Sucesso!");
      }, error => {
        console.log(error);
        this.global.errorTreat(error);
      })
    } else
      this.toastr.warning("Você precisa realizar o upload de uma nova foto!", "Atenção!");
  }

  getState() {
    this.store.select((state: any) => {
      if (state.newAppState) {
        const { usuario } = state.newAppState;
        this.state = { usuario };
      }
    }).subscribe();
  }

  async resetPassword() {
    this.isSaving = true

    this.usuarioService.changeUserPassword(this.credentials, this.state.usuario.id_usuario)
      .then((resp) => {
        this.toastr.success("Senha alterada com sucesso")
      })
      .catch((error) => {
        this.global.errorTreat(error);
      })
      .finally(() => {
        this.isSaving = false
      })
  }
}