import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { authSuccess, fillCurrentUser } from "../../state.actions";
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data-service/data.service';
import { ActionTypes } from 'src/app/models/enums/action-types.enum';


@Injectable()
export class LoginEffects {

    public setToken$ = createEffect(() => this.actions$.pipe(
        ofType(authSuccess),
        map(action => {            
            let module = sessionStorage.getItem('module');
            this.dataService.getProjetosAndNavigate(module);
            // this.dataService.navigateToModule(module);
            this.dataService.saveAccess().subscribe();
            return ({ type: ActionTypes.FILL_CURRENT_USER, payload: action.payload.usuario })
        })
    ))

    constructor(
        private actions$: Actions,
        private router: Router,
        private dataService: DataService
    ) { }
}