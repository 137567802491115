// Angular modules
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { HttpClientModule } from '@angular/common/http';

// ThirdParty and Liga Modules
import { NgbModule, NgbDateParserFormatter, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { MainModule } from './modules/main/main.module';
import { MatTableModule } from '@angular/material/table';

// Components
import { AppComponent } from './app.component';
import { LoginComponent } from './shared-components/login/login.component';
import { Auth } from './models/auth.model';

// State imports
import { reducer } from './_state/state.reducer';
import { LoginEffects } from './_state/effects/auth/login.effects';
import { ToastrModule } from 'ngx-toastr';
import { EditorModule } from '@tinymce/tinymce-angular';

import { NgxMaskModule, IConfig } from 'ngx-mask';
import { CustomDateParserFormatter } from './custom-date-parser-formatter';
import { CustomDatepickerI18n } from './custom-datepicker-i18n';
import { ClickOutsideModule } from 'ng-click-outside';
import { OrderModule } from 'ngx-order-pipe';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    MainModule,
    BrowserAnimationsModule,
    StoreModule.forRoot({ newAppState: reducer }),
    EffectsModule.forRoot([
      LoginEffects
    ]),
    ToastrModule.forRoot(),
    EditorModule,
    NgxMaskModule.forRoot(),
    ClickOutsideModule,
    OrderModule
  ],
  providers: [
    Auth,
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ],
  bootstrap: [AppComponent],
  exports: [
    // LoadingComponent
  ]
})
export class AppModule { }
