import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, pipe } from 'rxjs';
import { AppState } from 'src/app/_state';
import { Store } from "@ngrx/store";
import { token } from '../_state/selectors/auth/auth.selector';
import { authSuccess } from '../_state/state.actions';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  stateSubscription: any;
  state: any;

  constructor(private router: Router, private store: Store<AppState>) { }

  getAuthState(): Observable<any> {
    return this.store.select(token)
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean | Promise<boolean> {
    return new Observable<boolean>((observer) => {
      this.getAuthState().subscribe((response) => {
        let hasCachedState: any;
        let state = JSON.parse(localStorage.getItem('state'));
        const isLoggedIn = (!!response && response.length > 0 );
        if(state && state.auth)
          hasCachedState = state.auth.token != null;

        // if (!isLoggedIn && hasCachedState) {
        //   this.store.dispatch(authSuccess({payload: state}))
        // }

        observer.next(isLoggedIn || hasCachedState)
        observer.complete()
        if (!isLoggedIn) {
          this.router.navigate(['login'])
        }
      })

    })
  }
}
