import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from '../../../_state';
import * as moment from 'moment';
import {NotificacaoMensagemModel, NotificacaoMensagemTratadaModel} from '../../../models/notificacao-mensagem.model';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificacaoService {

  gatewayAPI = environment.api;
  state: any;

  constructor(private http: HttpClient, private store: Store<AppState>) {
    this.getState();
  }

  getNotificacao(idUsuario: number): Observable<NotificacaoMensagemTratadaModel> {
    return this.http.get<NotificacaoMensagemModel[]>(`${this.gatewayAPI}notification/usuario/${idUsuario}`, this.getHeader()).pipe(
      map(this.totalMensagensNaoLidas),
      map(this.segmentandoListPorData)
    );
  }

  marcarNotificacaoComoLida(idUsuario: number, idNotificacoes: number[]): Observable<any> {
    const dto = {
      notifications: idNotificacoes,
      plataforma: 'site'
    };
    return this.http.post(`${this.gatewayAPI}notification/usuario/${idUsuario}/read`, dto, this.getHeader());
  }

  private segmentandoListPorData(obj): NotificacaoMensagemTratadaModel {
    const arrayMensagens = [];
    let arrayTemp = [];
    let dataAtual;

    // obj.mensagens.sort((a, b) => (
    //   moment().format(a.data_envio.substring(1, 10)) > moment().format(b.data_envio.substring(1, 10))
    // ) ? -1 : 1);
    obj.mensagens.forEach((mensagem, index, array) => {
      const dataEnvio = mensagem.data_envio ? moment().format(mensagem.data_envio.substring(1, 10)) : null;
      if (!dataAtual) {
        dataAtual = dataEnvio;
      }

      if (dataAtual === dataEnvio) {
        arrayTemp.push(mensagem);
      } else {
        dataAtual = dataEnvio;
        arrayMensagens.push(arrayTemp);
        arrayTemp = [];
        arrayTemp.push(mensagem);
      }

      if (index === array.length - 1) {
        arrayMensagens.push(arrayTemp);
      }
    });
    return {
      mensagensNaoLidas: obj.mensagensNaoLidas,
      mensagens: arrayMensagens
    };
  }

  private totalMensagensNaoLidas(mensagens: NotificacaoMensagemModel[]) {
    const mensagensNaoLidas = [];
    mensagens.forEach(mensagem => {
      if (!mensagem.data_leitura) {
        mensagensNaoLidas.push(mensagem.id_notificacao);
      }
    });
    return {
      mensagensNaoLidas,
      mensagens
    };
  }

  private getHeader() {
    return {
      headers: new HttpHeaders().set(
        'Authorization', 'bearer ' + this.state.application.token
      )
    };
  }

  private getState() {
    this.store.select((state: any) => {
      if (state.newAppState &&  state.newAppState.application && state.newAppState.application.token) {
        const { application, selectedEmpresa, selectedIdProjetoTurma } = state.newAppState;
        this.state = { application, selectedEmpresa, selectedIdProjetoTurma };
      }
    }).subscribe();
  }
}
