import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor(private toastr: ToastrService) { }

  stateToEdition(obj) {
    let newObject = {};
    Object.keys(obj).forEach(field => { newObject[field] = obj[field] });
    return newObject;
  }

  updateDatatable() {
    let input: HTMLElement;
    input = document.querySelector('.btn-filter-action');
    if (input)
      input.click();
  }

  dateToInput(date) {
    if (date)
      return date.substr(0, 10);
  }

  errorTreat(error) {
    console.log(error);
    if (Array.isArray(error.error.message)) {
      error.error.message.forEach(msg => {
        this.toastr.error(msg.message, "Ocorreu um erro!");
      });
    }
    else
      this.toastr.error(error.error.message, "Ocorreu um erro!");
  }

  generateImageURL(url) {
    return "url(" + url + ")";
  }

  deep(value) {
    if (typeof value !== 'object' || value === null)
      return value
    if (Array.isArray(value))
      return this.deepArray(value)
    return this.deepObject(value)
  }

  deepArray<T extends any[]>(collection: T) {
    return collection.map((value) => {
      return this.deep(value)
    })
  }

  deepObject<T>(source: T) {
    const result = {}
    Object.keys(source).forEach((key) => {
      const value = source[key]
      result[key] = this.deep(value)
    }, {})
    return result as T
  }

  setFirstProjectOfList() {
    let scrollbar = document.querySelector('.liga-admin-left-menu-container');
    scrollbar.scrollTo({ top: 0, behavior: 'smooth' });
    
    let input: HTMLElement;
    input = document.querySelector('.project-list.first');
    if (input)
      input.click();
  }
  getBrowserName() {
    var nAgt = navigator.userAgent;
    var browserName = navigator.appName;
    var fullVersion = '' + parseFloat(navigator.appVersion);
    var nameOffset, verOffset, ix;

    // In Opera, the true version is after "Opera" or after "Version"
    if ((verOffset = nAgt.indexOf("Opera")) != -1) {
      browserName = "Opera";
      fullVersion = nAgt.substring(verOffset + 6);
      if ((verOffset = nAgt.indexOf("Version")) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
      browserName = "Microsoft Internet Explorer";
      fullVersion = nAgt.substring(verOffset + 5);
    }
    // In Chrome, the true version is after "Chrome" 
    else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
      browserName = "Chrome";
      fullVersion = nAgt.substring(verOffset + 7);
    }
    // In Safari, the true version is after "Safari" or after "Version" 
    else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
      browserName = "Safari";
      fullVersion = nAgt.substring(verOffset + 7);
      if ((verOffset = nAgt.indexOf("Version")) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In Firefox, the true version is after "Firefox" 
    else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
      browserName = "Firefox";
      fullVersion = nAgt.substring(verOffset + 8);
    }
    // In most other browsers, "name/version" is at the end of userAgent 
    else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
      (verOffset = nAgt.lastIndexOf('/'))) {
      browserName = nAgt.substring(nameOffset, verOffset);
      fullVersion = nAgt.substring(verOffset + 1);
      if (browserName.toLowerCase() == browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }

    return browserName;
  }

  getOSName() {
    var OSName = "Unknown OS";
    if (navigator.appVersion.indexOf("Win") != -1) OSName = "Windows";
    if (navigator.appVersion.indexOf("Mac") != -1) OSName = "MacOS";
    if (navigator.appVersion.indexOf("X11") != -1) OSName = "UNIX";
    if (navigator.appVersion.indexOf("Linux") != -1) OSName = "Linux";
  
    return OSName;
  }

  setToPascalCase(nome){
    let pascal = nome.replace(/\w+/g, function(w){
      return w[0].toUpperCase() + w.slice(1).toLowerCase();
    });
    return pascal
  }

  formatLocalDate(dataToLocal, operador){
    let tempDate = new Date(dataToLocal)
    let data;
    if (operador == 'carregar')
      data = new Date(tempDate.getTime() - (tempDate.getTimezoneOffset() * 60000)).toISOString().substring(0,16);
    if (operador == 'enviar')
      data = new Date(tempDate.getTime()).toISOString().substring(0,16);
    return data;
  }
}
