import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Auth } from 'src/app/models/auth.model';
import { AppState } from 'src/app/_state';
import { Store } from '@ngrx/store';
import { authSuccess, authFailed } from 'src/app/_state/state.actions';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { ToastrService } from 'ngx-toastr';
import { orderBy } from 'lodash';
import { GlobalService } from 'src/app/services/global-service/global.service';

@Component({
  selector: 'liga-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private modalService: NgbModal,
    public credentials: Auth,
    private store: Store<AppState>,
    private authService: AuthService,
    private toastr: ToastrService,
    private global: GlobalService
  ) {
    this.credentials = new Auth();
  }

  stateSubscription: any;
  state: any;
  loginLoadingMock = false;
  selectedEmpresaID: any;
  empresasArray: any = [];
  keepResponse: any;

  ngOnInit(): void {
    this.tryAutomaticLogin();
  }

  tryAutomaticLogin() {
    let token = sessionStorage.getItem('token');
    let id_usuario = sessionStorage.getItem('id_usuario');
    let id_empresa = sessionStorage.getItem('id_empresa');

    if (token && id_usuario) {
      this.authService.automaticLogin(token).toPromise()
        .then((response) => {
          response.token = token;
          response.selectedEmpresaID = id_empresa;
          this.store.dispatch(authSuccess({ payload: response }))
          this.modalService.dismissAll();
        })
        .catch(error => {
          console.log(error);
          this.global.errorTreat(error);
        })
    }

  }

  apiLogin(content) {
    this.loginLoadingMock = true;
    this.authService.login(this.credentials).toPromise()
      .then((response) => {
        this.loginLoadingMock = false;
        this.empresasArray = [];
        let empresasAdmin = this.getEmpresasArray(response.empresas, response.papeis);
        if (empresasAdmin.length == 1)
          this.store.dispatch(authSuccess({ payload: response }))
        else if (empresasAdmin.length > 1) {
          this.keepResponse = response;
          response.empresas.allIds.forEach(idEmpresa => {
            this.empresasArray.push({ ...response.empresas.byId[idEmpresa] })
          })
          this.empresasArray = orderBy(this.empresasArray, ['nome'], ['asc']);
          this.openModal(content);
        }
      })
      .catch(error => {
        console.log(error);
        this.loginLoadingMock = false;
        this.store.dispatch(authFailed({ payload: error.message }))
        this.global.errorTreat(error);
      })
  }

  getEmpresasArray(empresas, papeis) {
    let empresasTemp = [];
    let isAluno = true;
    empresas.allIds.forEach(id => {
      empresas.byId[id].papeis.forEach(id_papel => {
        if (papeis.byId[id_papel].papel.toLowerCase() != 'aluno')
          isAluno = false;
      });
      if (!isAluno)
        empresasTemp.push(empresas.byId[id]);
    });
    if (isAluno)
      this.toastr.error("Você não tem permissão para acessar esta plataforma!");
    return empresasTemp;
  }

  getState() {
    this.stateSubscription = this.store.select((state: any) => {
      const { empresas } = state.newAppState;
      this.state = { empresas };
    }).subscribe();
  }

  openModal(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => { });
  }

  modalLogin() {
    if (this.selectedEmpresaID) {
      this.keepResponse.selectedEmpresaID = this.selectedEmpresaID;
      this.store.dispatch(authSuccess({ payload: this.keepResponse }))
      this.modalService.dismissAll();
      this.keepResponse = null;
    }
  }
}
