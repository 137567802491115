import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/_state';
import { fillProjectData, fillCurrentUser } from 'src/app/_state/state.actions';
import { GlobalService } from '../global-service/global.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  gatewayAPI = environment.api;
  token: any;
  state: any;
  stateSubscription: any;

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private router: Router,
    private global: GlobalService,
    private toastr: ToastrService
  ) {
    this.getState();
  }

  getState() {
    this.stateSubscription = this.store.select((state: any) => {
      if (state.newAppState && state.newAppState.application && state.newAppState.application.token) {
        const { application, selectedEmpresa, usuario } = state.newAppState;
        this.state = { application, selectedEmpresa, usuario };
      }
    }).subscribe();
  }

  getProjetosAndNavigate(module) {
    this.getState();
    this.http.get(this.gatewayAPI + 'home/' + this.state.selectedEmpresa.id_empresa, this.getHeader())
      .subscribe((dados: any) => {
        this.store.dispatch(fillProjectData({ payload: dados.data }))
        this.navigateToModule(module);
      }, error => {
        this.global.errorTreat(error);
      })
  }

  navigateToModule(module) {
    if (module)
      this.router.navigateByUrl(`${module}`);
    else
      this.router.navigate(['main']);
  }

  importarUsuario(file: any): Observable<any> {
    let form: FormData = new FormData()
    form.append("service", 'api')
    form.append("api", 'importar/alunos')
    form.append("method", 'post')
    form.append("body[arquivo]", file)
    return this.http.post(this.gatewayAPI + 'custom-api', form, this.getHeader())
  }

  getHeader() {
    const header = {
      headers: new HttpHeaders()
        .set('Authorization', 'bearer ' + this.state.application.token)
    };
    return header;
  }

  changePicture(foto) {
    const headers = { 'Content-Type': 'multipart/form-data', ...this.getHeader() }
    return this.http.post(this.gatewayAPI + 'usuario/change-photo/' + this.state.usuario.id_usuario, foto, headers).toPromise();
  }

  saveAccess(): Observable<any> {
    const headers = this.getHeader()
    const data =
    {
      "service": "api",
      "api": "acesso/salvar-acesso",
      "method": "post",
      "body": {
        "sistema": this.global.getOSName(),
        "agente": this.global.getBrowserName(),
        "acesso_tipo": "login",
        "instance": null
      }
    }

    return this.http.post(this.gatewayAPI + 'custom-api', data, headers);
  }
}
