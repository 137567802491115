import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main.component';
import { HeaderComponent } from './components/header/header.component';

import { MatTabsModule } from '@angular/material/tabs';
import { MainRoutingModule } from './main.routing.module';
import {SidebarContentModule} from '../../../../projects/ui/src/lib/core/sidebar-content/sidebar-content.module';
import {MensagemListModule} from '../../../../projects/ui/src/lib/layout/mensagem/mensagem-list.module';
import {CardDataModule} from '../../../../projects/ui/src/lib/layout/card-data/card-data.module';

@NgModule({
  declarations: [MainComponent, HeaderComponent],
  imports: [
    CommonModule,
    MatTabsModule,
    MainRoutingModule,
    SidebarContentModule,
    MensagemListModule,
    CardDataModule
  ]
})
export class MainModule { }
