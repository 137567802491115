export class Oferta {
  id_oferta: number;
  id_empresa: number;
  id_usuario:number;
  nome: string;
  descricao: string;
  ativa: boolean;
  data_registro: Date;
  data_inicio: Date;
  data_fim: Date;
  categorias: number[];
  ofertaCategoria: any[];
  projetos: any[];
  ofertaProjetoTurma: any[];
  ofertaCupom: any[];
  link: string;
}