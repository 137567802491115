export enum ActionTypes {
    LOGIN_FAILED = '[AUTH] Failed',
    LOGIN_SUCCESS = '[AUTH] Token acquired',
    DO_LOGIN = '[AUTH] Login',
    EMPRESA_SELECIONADA = '[EMPRESA] Empresa Selecionada',
    FILL_PROJECT_DATA = '[PROJECT] Project data received',
    FILL_NEW_PROJECT = '[PROJECT] New project data received',
    FILL_DELETED_PROJECT_ID = '[PROJECT] Project deleted id received',
    UPDATE_PROJECTS = '[PROJECT] Update projects array',
    FILL_ID_PROJETO_TURMA = '[PROJETO_TURMA] ID Projeto Turma received',
    FILL_TURMAS_DATA = '[TURMAS] data received',
    FILL_SELECTED_TURMA_ID = '[TURMA] turma id received',
    FILL_PROJECT_DETAIL = '[PROJECT] Project Details',
    FILL_FASES_TURMA = '[FASES] Filled',
    TOGGLE_ASIDE_ALUNOS = '[ASIDE ALUNOS] Toggled',
    SET_ASIDE_ALUNOS = '[ASIDE TURMAS] Close Any Student Details',
    FILL_SELECTED_USUARIO_ID = '[ASIDE ALUNOS] Usuario selected',
    FILL_SELECTED_FASE_TURMA_ID = '[TIMELINE] Fase turma selected',
    FILL_CURRENT_USER = '[LOGIN] Current User received',
    FILL_CURRENT_PROJETO = '[PROJETO] Current project received',
    SHOW_ADMIN_USER_EDITION = '[ADMIN] Show edit user screen',
    EDITION_USER = '[ADMIN] User that is being edited',
    FILL_SUPORT_MODAL = "[TURMAS] Fill modal suport data",
    FILL_FORUM_MODAL = "[TURMAS] Fill modal forum details data",
    FILL_PARTICIPANTES_MODAL = "[TURMAS] Fill participantes modal data",
    FILL_AVALIACAO_MODAL = "[TURMAS] Fill avaliacao modal data",
    FILL_COORDENACAO_MODAL = "[ADMIN] Fill coordenacao modal data",
    FILL_COORDENADORES_MODAL = "[ADMIN] Fill coordenador modal data",
    SHOW_MODAL_ADD_USUARIO_PARTICIPANTES = "[TURMAS] aparecer e sumir modal de add usuario em participantes",
    SHOW_USUARIO_PICKER = "[ADMIN] show usuarioPickerComponent",
    FILL_USUARIO_PICKED = "[ADMIN] fill picked usuario data",
    CLEAR_STATE = "[ALL] clear all state data",
    FILL_CONFIRM_MODAL = "[SHARED] Fill modal question showing modal",
    FILL_EDITION_CASOS_DE_SUCESSO = "[ADMIN] Success case that is being edited",
    SHOW_ADMIN_CASOS_DE_SUCESSO_EDITION = "[ADMIN] Show edit Success case's screen",
    FILL_EDITION_SOFT_SKILLS = "[ADMIN] Soft skills that is being edited",
    SHOW_ADMIN_SOFT_SKILLS_EDITION = "[ADMIN] Show edit Soft skills's screen",
    FILL_EDITION_OFERTA = "[ADMIN] Ofertas that is being edited",
    SHOW_ADMIN_OFERTA_EDITION = "[ADMIN] Show edit Ofertas's screen",
    FILL_DELETE_TENTATIVA = "[TURMAS] Deleta uma tentativa",
    NOTIFICACOES = '[NOTIFICACAO] Get notificacao',
    SHOWMENU = '[MENU] Show menu',
    SHOWNOTIFICACOES = '[NOTIFICACAO] Show notificacao',
    FILL_DADOS_NOTIFICACAO = '[NOTIFICACAO] Data for send notification',
    FILL_EDITION_CUPOM = "[ADMIN] Cupom that is being edited",
    SHOW_ADMIN_CUPOM_EDITION = "[ADMIN] Show edit Cupons screen",
    FILL_DADOS_PERFIL_TESTE = "[ADMIN] Perfil Teste that is being edited",
    SHOW_PERFIL_TESTE_EDITION = "[ADMIN] Show edit Perfil Teste screen",
    FILL_DADOS_PERFIL_CATALOGO = "[ADMIN] Perfil Catalogo that is being edited",
    SHOW_CATALOGO_MODAL = "[ADMIN] Show edit Catalogo modal",
}
