<div
  class="sidebar-content-container"
  [class.turma]="!showSidebar"
  id="scroll-sidebar-content"
  [@Sidebar-content]="transition"
  (clickOutside)="closeSidebarContent()"
>
  <div class="header">
    {{titulo}}
    <i class="fas fa-times pointer" (click)="closeSidebarContent()"></i>
  </div>
  <ng-content></ng-content>
</div>
