import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { AuthGuardService } from 'src/app/guards/auth-guard.service';
import { MainComponent } from './main.component';
import { PerfilComponent } from 'src/app/shared-components/perfil/perfil.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
          path: 'admin',
          loadChildren: () => import('../../modules/admin/admin.module').then(m => m.AdminModule),
          canActivate: [AuthGuardService]
      },
      {
          path: 'turmas',
          loadChildren: () => import('../../modules/turmas/turmas.module').then(m => m.TurmasModule),
          canActivate: [AuthGuardService]
      },
      {
          path: 'projetos',
          loadChildren: () => import('../../modules/projetos/projetos.module').then(m => m.ProjetosModule),
          canActivate: [AuthGuardService]
      },
      {
          path: 'perfil',
          component: PerfilComponent,
          canActivate: [AuthGuardService]
      },
    ]
  },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class MainRoutingModule { }
