import {AfterContentInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges} from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'ui-sidebar-content',
  templateUrl: './sidebar-content.component.html',
  styleUrls: ['./sidebar-content.component.scss'],
  animations: [
    trigger('Sidebar-content', [
      state('open', style({
        right: '0px'
      })),
      state('closed', style({
        right: '-613px'
      })),
      transition('open => closed', [
        animate('.5s 0ms ease-in-out')
      ]),
      transition('closed => open', [
        animate('.5s 0ms ease-in-out')
      ]),
    ]),
  ]
})
export class SidebarContentComponent implements OnInit, OnChanges {

  @Input() titulo: string;
  @Input() showSidebar: boolean;
  @Output() closeSidebar: EventEmitter<any> = new EventEmitter<any>();
  transition = 'closed';

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.showSidebar.currentValue) {
      this.transition = 'open';
    } else {
      this.transition = 'closed';
    }
  }

  closeSidebarContent() {
    this.closeSidebar.emit();
    this.transition = 'closed';
  }

}
