import { Component, OnInit, ViewChild } from '@angular/core';
import { AppState } from 'src/app/_state';
import { Store } from '@ngrx/store';
import { PermissionsService } from 'src/app/services/permissions/permissions.service';
import { Router } from '@angular/router';
import { NotificacaoMensagemTratadaModel } from '../../../../models/notificacao-mensagem.model';
import { NotificacaoService } from '../../service/notificacao.service';
import { clearState, notificacoes, showMenu, showNotificacoes } from '../../../../_state/state.actions';
import { mergeMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { GlobalService } from 'src/app/services/global-service/global.service';

@Component({
  selector: 'liga-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  stateSubscription: any;
  state: any;
  subscriptions: Subscription[] = [];
  linksMenu = [
    { label: 'admin', link: 'admin', show: this.perm.can(['Administrador']) },
    { label: 'projetos', link: 'projetos', show: this.perm.can(['Professor Conteudista', 'Administrador', 'Coordenador']) },
    { label: 'turmas', link: 'turmas', show: this.perm.can(['Professor', 'Administrador', 'Coordenador']) },
    { label: 'Atendimento NEAD', link: 'nead', show: this.perm.can(['NEAD']) },
    { label: 'Configurações', link: 'perfil', show: true },
    { label: 'Ajuda', link: 'ajuda', show: true },
    { label: 'Sair', link: 'logout', show: true },
  ];

  @ViewChild('headerTabGroup') headerTabGroup;

  constructor(
    private store: Store<AppState>,
    public perm: PermissionsService,
    private router: Router,
    private notificacaoService: NotificacaoService,
    public global: GlobalService
  ) { }

  ngOnInit(): void {
    this.getState();
    this.getNotificacoes();
    this.getRouter();
  }

  getState() {
    this.stateSubscription = this.store.select((state: any) => {
      if (state.newAppState) {
        const { selectedEmpresa, usuario, projetos, turmas, notificacoes, showMenu, showNotificacoes } = state.newAppState;
        this.state = { selectedEmpresa, usuario, projetos, turmas, notificacoes, showMenu, showNotificacoes };
      }
    }).subscribe();
  }

  getNotificacoes() {
    this.notificacaoService.getNotificacao(this.state.usuario.id_usuario).subscribe(
      (data: NotificacaoMensagemTratadaModel) => {
        this.store.dispatch(notificacoes({ payload: data }));
      }
    );
  }

  getRouter() {
    if (this.perm.can(['Administrador'])) {
      this.router.navigate(['admin']);
      return;
    }
    if (this.perm.can(['Professor Conteudista', 'Administrador', 'Coordenador'])) {
      this.router.navigate(['projetos']);
      return;
    }
    if (this.perm.can(['Professor', 'Administrador', 'Coordenador'])) {
      this.router.navigate(['turmas']);
      return;
    }
    if (this.perm.can(['NEAD'])) {
      this.router.navigate(['nead']);
      return;
    }
    this.router.navigate(['ajuda']);
    return;
  }

  abrirMenu() {
    setTimeout(() => {
      this.store.dispatch(showMenu({ payload: true }));
    });
  }

  fecharMenu() {
    if (this.state.showMenu) {
      this.store.dispatch(showMenu({ payload: false }));
    }
  }

  abrirNotificacoes() {
    setTimeout(() => {
      this.store.dispatch(showNotificacoes({ payload: true }));
    });
  }

  fecharNotificacoes() {
    if (this.state.showNotificacoes) {
      this.marcarNotificacaoComoLida();
      this.store.dispatch(showNotificacoes({ payload: false }));
    }
  }

  mudarDeRota(rota: string) {
    if (rota === 'logout')
      this.logout();
    else if (rota === 'ajuda')
      window.open('https://ligaeducacional.movidesk.com/kb', '_blank');
    else if (rota === 'nead')
      window.open('https://ligaeducacional.movidesk.com/form/2961/', '_blank');
    else
      this.router.navigate([rota]).then(() => {
        sessionStorage.setItem('module', rota);
        this.fecharMenu();
      });

  }

  logout() {
    sessionStorage.clear();
    this.router.navigate(['login']);
    this.store.dispatch(clearState());
  }

  marcarNotificacaoComoLida() {
    if (this.state.notificacoes) {
      if (this.state.notificacoes.mensagensNaoLidas.length > 0) {
        const subscription = this.notificacaoService.marcarNotificacaoComoLida(
          this.state.usuario.id_usuario, this.state.notificacoes.mensagensNaoLidas
        )
          .pipe(
            mergeMap(data => this.notificacaoService.getNotificacao(this.state.usuario.id_usuario))
          )
          .subscribe((data: NotificacaoMensagemTratadaModel) => {
            this.store.dispatch(notificacoes({ payload: data }));
          }
          );
        this.subscriptions.push(subscription);
      }
    }
  }

}
