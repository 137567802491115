
export class DataUtil {
  // converte o numero em nome do mês
  pegarMesEmPt(mes: number) {
    switch (mes) {
      case 0:
        return 'Janeiro';
        break;
      case 1:
        return 'Fevereiro';
        break;
      case 2:
        return 'Março';
        break;
      case 3:
        return 'Abril';
        break;
      case 4:
        return 'Maio';
        break;
      case 5:
        return 'Junho';
        break;
      case 6:
        return 'Julho';
        break;
      case 7:
        return 'Agosto';
        break;
      case 8:
        return 'Setembro';
        break;
      case 9:
        return 'Outubro';
        break;
      case 10:
        return 'Novembro';
        break;
      case 11:
        return 'Dezembro';
        break;
    }
  }
}
