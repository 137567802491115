<div class="login-container full-height">
    <div class="d-flex justify-content-center">
        <img src="../../../assets/icons/LOGO_LIGA.svg" alt="">
    </div>
    <form class="form-width manutencao">
        <!-- <img src="assets/imgs/manutencao.png"> -->
        <div class="form-group">
            <input type="email" [(ngModel)]="credentials.email" name="email" class="form-control" placeholder="Usuário">
        </div>
        <div class="form-group">
            <input type="password" [(ngModel)]="credentials.password" name="passoword" class="form-control" placeholder="Senha">
        </div>
    </form>
    <button class="btn mt-2 btn-primary form-width" (click)="apiLogin(content)">
        <span *ngIf="!loginLoadingMock">LOGIN</span>
        <liga-loading *ngIf="loginLoadingMock" [color]="'white'" [size]="'18px'" [thickness]="'6px'"></liga-loading>
    </button>
</div>
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Seleção de empresa</h4>
        <button type="button" class="close btn-close-modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label for="empresas">Selecione a empresa que deseja acessar</label>
            <select class="form-control" id="empresas" [(ngModel)]="selectedEmpresaID">
                <option value=undefined selected disabled>Selecione a empresa que deseja acessar</option>
                <option [value]="empresa.id_empresa" *ngFor="let empresa of empresasArray">{{empresa.nome}}</option>
            </select>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modalLogin()">Entrar</button>
    </div>
</ng-template>
