import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppState } from 'src/app/_state';
import { Observable, of, throwError } from 'rxjs';
import { environment } from "../../../environments/environment";
import { Store } from '@ngrx/store';
import { Auth } from 'src/app/models/auth.model';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  public state: AppState
  public environment = environment
  public auth: Auth;

  constructor(private http: HttpClient, private store: Store<AppState>) {
    this.store.select((state: AppState) => state.auth).subscribe((response) => {
      this.auth = response;
    })
  }
  
  login(credentials): Observable<any> {
    if (!credentials.email || !credentials.password) 
      return throwError("No credentials provided")
      
    return this.http.post(`${this.environment.api}login`, credentials)
  }

  automaticLogin(token): Observable<any> {
    return this.http.get(`${this.environment.api}login-dados`, this.getHeaderWithToken(token));
  }

  getHeaderWithToken(token) {
    return {
      headers: new HttpHeaders().set(
        'Authorization', 'bearer ' + token
      )
    };
  }
}
