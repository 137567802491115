<div class="container w-50 d-flex justify-content-center flex-column align-items-center mt-5">
    <h2>Alterar imagem</h2>
    <div class="row w-50">
        <div class="d-flex justify-content-center col-12 mt-3 mb-4">
            <div class="user" *ngIf="state?.usuario?.foto" [style.background-image]="global.generateImageURL(state?.usuario?.foto)"></div>
            <div class="user" *ngIf="!state?.usuario?.foto" [style.background-image]="global.generateImageURL('assets/imgs/profile-img.jpg')"></div>
        </div>
        <div class="input-label d-flex justify-content-between align-items-center pointer mb-4" (click)="callInput()">
            <span *ngIf="!photoName">Usar do computador</span>
            <span *ngIf="photoName?.length <= 20">{{photoName}}</span>
            <span *ngIf="photoName?.length > 20">{{photoName | slice: 0:20}}...</span>
            <i class="far fa-image"></i>
            <input type="file" class="d-none upload-picture" (change)="prepareUpload($event)">
        </div>
        <button type="button" (click)="uploadPicture();$event.stopPropagation()" class="btn liga-btn save-btn mb-3">
            <span *ngIf="!state?.photoLoading">ALTERAR</span>
            <div *ngIf="state?.photoLoading" class="text-center"> 
                <div class="spinner-border spinner-border-md" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </button>
    </div>
    <div class="divisor"></div>
</div>


<div class="container w-50 d-flex justify-content-center flex-column align-items-center mt-5">
    <h2>Troca de senha</h2>
    <form action="" class="w-50">
        <div class="form-group">
            <label for="oldpassword">Senha antiga</label>
            <input required type="password" name="oldpassword" class="form-control liga-input-text"
                placeholder="Digite aqui..." [(ngModel)]='credentials.oldpassword' #oldpassword="ngModel">
        </div>
        <div class="form-group">
            <label for="newpassword">Senha Nova</label>
            <input type="password" name="newpassword" class="form-control liga-input-text" #newpassword="ngModel"
                placeholder="Digite aqui..." [(ngModel)]='credentials.newpassword'>
        </div>
        <div class="form-group">
            <label for="againpassword">Confirmação da senha</label>
            <input type="password" name="againpassword" class="form-control liga-input-text" placeholder="Digite aqui..."
                [(ngModel)]='credentials.againpassword' #againpassword="ngModel"
                [pattern]="credentials.newpassword">
        </div>
        <div *ngIf="againpassword.invalid && (againpassword.dirty || againpassword.touched) && (newpassword.dirty || newpassword.touched)"
            class="alert alert-danger">
            <div *ngIf="againpassword.errors.pattern">
                Senhas não coincidem
            </div>

        </div>
        <div  class="alert alert-danger" *ngIf="oldpassword.invalid && (oldpassword.touched || oldpassword.dirty)">
            <div *ngIf="oldpassword.errors.required">
                Senha antiga não informada
            </div>

        </div>
        <button type="button" class="btn liga-btn save-btn mb-3 mt-3" [disabled]='isSaving || againpassword.invalid || oldpassword.invalid' (click)='resetPassword()'>
            <span *ngIf='!isSaving'>SALVAR</span>
            <div class="spinner-border spinner-border-sm" *ngIf='isSaving'></div>
        </button>
    </form>
</div>
