<div class="header-container w-100">
  <div class="container-icons">
    <button class="container-notificacao mr-5" (click)="abrirNotificacoes()">
      <img class="icon-notificacao" src="assets/icons/sino_branco.svg">
      <label *ngIf="state.notificacoes?.mensagensNaoLidas.length > 0">
        {{state.notificacoes?.mensagensNaoLidas?.length}}
      </label>
    </button>
    <button (click)="abrirMenu()">
      <img src="assets/icons/menu.svg">
    </button>
  </div>
  <div class="user-info ml-5">
    <div class="img-container" *ngIf="state?.usuario?.foto" [style.background-image]="global.generateImageURL(state?.usuario?.foto)"></div>
    <div class="img-container" *ngIf="!state?.usuario?.foto" [style.background-image]="global.generateImageURL('assets/imgs/profile-img.jpg')"></div>
    <div class="project-data">
      <div class="name">{{state?.usuario?.nome_social}}</div>
      <div class="indicators">
        <div>PROJETOS
          <span *ngIf="state?.projetos?.length">{{state?.projetos?.length}}</span>
          <span *ngIf="!state?.projetos?.length">0</span>
        </div>
        <div>TURMAS
          <span *ngIf="state?.turmas?.length">{{state?.turmas?.length}}</span>
          <span *ngIf="!state?.turmas?.length">0</span>
        </div>
      </div>
    </div>
  </div>
</div>

<!--MENU-->
<ui-sidebar-content
  titulo="Menu"
  [showSidebar]="state.showMenu"
  (closeSidebar)="fecharMenu()"
>
  <ul class="menu-list col-12">
    <ng-container *ngFor="let item of linksMenu">
      <li
        class="pointer d-flex align-items-center justify-content-start menu-item border-bottom"
       (click)="mudarDeRota(item.link)" *ngIf="item.show"
      >
        <div class="icon mr-2" *ngIf="item.image">
          <img [src]="item.image" [alt]="item.label">
        </div>
        <a class="text text-uppercase">{{item.label}}</a>
      </li>
    </ng-container>
  </ul>
</ui-sidebar-content>
<!--MENU-->

<!--NOTIFICAÇÕES-->
<ui-sidebar-content
  titulo="Notificações"
  [showSidebar]="state.showNotificacoes"
  (closeSidebar)="fecharNotificacoes()"
>
  <ui-card-data
    *ngFor="let mensagens of state.notificacoes?.mensagens"
    [data]="mensagens[0].data_envio"
  >
    <ui-mensagem-list
      *ngFor="let mensagem of mensagens; let i = index"
      [mensagem]="mensagem"
      [ultimaMensagem]="mensagens.length - 1 === i"
      [mensagemLida]="!!mensagem.data_leitura"
    ></ui-mensagem-list>
  </ui-card-data>
  <p *ngIf="state.notificacoes?.mensagens.length === 0">Você ainda não tem notificação.</p>
</ui-sidebar-content>
<!--NOTIFICAÇÕES-->
