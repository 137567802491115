import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'liga-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  @Input() color;
  @Input() size;
  @Input() thickness;

  constructor() { }

  ngOnInit(): void {
    if(!this.color)
      this.color = 'gray';
  }

}
