/* tslint:disable:variable-name triple-equals curly */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { AppState } from 'src/app/_state';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  gatewayAPI = environment.api;
  v2 = environment.apiv2;
  stateSubscription: any;
  state: any;

  constructor(private http: HttpClient, private store: Store<AppState>) {
    this.getState();
  }

  getUserByRoleAndEmpresa(id_empresa: number, role: string): Promise<any> {
    return this.http.post(
      this.gatewayAPI + 'contexto-listar-usuarios', { papel: role, empresa: id_empresa }, this.getHeader()
    ).toPromise();
  }

  getUsuariosList(filters = null) {
    if (!filters || filters.filters.length == 0)
      filters = { filters: [{ field: 'empresa_id_empresa', value: this.state.selectedEmpresa.id_empresa }] };
    return this.http.post(this.gatewayAPI + 'usuario/datalist', filters, this.getHeader()).toPromise();
  }

  getCoordenacaoList(filters = null) {
    if (!filters || filters.filters.length == 0)
      filters = { filters: [{ field: 'id_empresa', value: this.state.selectedEmpresa.id_empresa }] };
    return this.http.post(this.gatewayAPI + 'coordenacao/datalist', filters, this.getHeader()).toPromise();
  }

  getCoordenacaoSimpleList(id_empresa) {
    const body = {
      "service": "api",
      "api": "coordenacao",
      "action": "index",
      "normalize": false,
      "body": {"where":[{"field": "id_empresa","operator": "=","value": id_empresa}],
        orientation: {"field": "nome","orientation": "asc"}
      }
    }
    return this.http.post(this.gatewayAPI + 'data-api', body, this.getHeader());
  }

  saveCoordenador(obj) {
    return this.http.post(this.gatewayAPI + 'coordenador', obj, this.getHeader()).toPromise();
  }

  updateCoordenador(obj) {
    return this.http.patch(this.gatewayAPI + 'coordenador/' + obj.id_coordenador, obj, this.getHeader()).toPromise();
  }

  getCoordenadoresList(filters = null) {
    if (!filters || filters.filters.length == 0)
      filters = { filters: [{ field: 'empresa_id_empresa', value: this.state.selectedEmpresa.id_empresa }] };
    return this.http.post(this.gatewayAPI + 'coordenador/datalist', filters, this.getHeader()).toPromise();
  }

  getSuportData(filters = null) {
    if (!filters || (filters.filters instanceof Array && filters.filters.length == 0)) {
      filters = { filters: [{ field: 'projeto_turma_id_projeto_turma', value: this.state.selectedIdProjetoTurma }] };
    }
    filters.filters = filters.filters.map(filter => {
      if (filter.field != 'projeto_turma_id_projeto_turma') {
        return filter;
      }
      filter.value = this.state.selectedIdProjetoTurma;
      return filter;
    });

    filters.sort = [
      {
        field: 'suporte_status_final',
        orientation: 'asc'
      },
      {
        field: 'suporte_data_abertura',
        orientation: 'desc'
      }
    ];
    return this.http.post(this.gatewayAPI + 'suporte/falecomprofessor/datalist', filters, this.getHeader()).toPromise();
  }

  getSuportDetails(id_suporte) {
    return this.http.get(this.gatewayAPI + 'suporte/falecomprofessor/' + id_suporte + '/detalhes', this.getHeader()).toPromise();
  }

  getParticipantesData(filters = null){
    filters = this.getFiltersProjetoRequired(filters, this.state.selectedIdProjetoTurma);
    return this.http.post(this.gatewayAPI + 'participante/datalist', filters, this.getHeader()).toPromise();
  }

  getFiltersEmpresaRequired(filters){
    if (!filters || filters.filters.length == 0)
      filters = { filters: [{ field: 'empresa_id_empresa', value: this.state.selectedEmpresa.id_empresa }] };
    else {
      filters.filters = filters.filters.map(filtro => ({
        field: filtro.field, value: filtro.value, type: filtro.field == 'empresa_id_empresa' ? 'select' : filtro.type, operator: filtro.operator ? filtro.operator : ''
      }));
      filters.filters.forEach(element => {
        if(element.operator == '')
          delete element.operator
      });
    }
    return filters
  }

  getFiltersProjetoRequired(filters, idProjetoTurma, fieldName = 'projeto_turma_id_projeto_turma'){
    const projectField = {field: fieldName, value: idProjetoTurma};
    if (!filters) {
      filters = {filters: [projectField]};
      return filters;
    }

    let alterou = false;
    filters.filters = filters.filters.map( item => {
      if (item.field != fieldName){
        return item;
      }
      item.value = idProjetoTurma;
      alterou = true;
      return item;
    });

    if (!alterou)
      filters.filters.push(projectField);

    return filters;
  }

  getAvaliacaoData(filters = null){
    filters = this.getFiltersProjetoRequired(filters, this.state.selectedIdProjetoTurma);
    return this.http.post(this.gatewayAPI + 'avaliacao/datalist', filters, this.getHeader()).toPromise();
  }

  getForumData(filters = null){
    filters = this.getFiltersProjetoRequired(filters, this.state.selectedIdProjetoTurma);

    return this.http.post(this.gatewayAPI + 'forum/datalist', filters, this.getHeader()).toPromise();
  }

  // --------- Relatorios ----------

  getRelatorioAppAcesso(filters = null){
    filters = this.getFiltersProjetoRequired(filters, this.state.selectedIdProjetoTurma);
    const data = {
      api: "relatorio-aplicacao-acessos",
      action: "datalist",
      normalize: false,
      params: null,
      body: filters
    }
    return this.http.post(this.gatewayAPI + 'data-api', data, this.getHeader()).toPromise();
  }

  getRelatorioProjAcesso(filters = null){
    filters = this.getFiltersProjetoRequired(filters, this.state.selectedIdProjetoTurma);
    const data = {
      api: "relatorio-projeto-acessos",
      action: "datalist",
      normalize: false,
      params: null,
      body: filters
    }
    return this.http.post(this.gatewayAPI + 'data-api', data, this.getHeader()).toPromise();
  }

  getRelatorioEntegaFase(filters = null){
    filters = this.getFiltersProjetoRequired(filters, this.state.selectedIdProjetoTurma);
    const data = {
      api: "relatorio-entrega-fases",
      action: "datalist",
      normalize: false,
      params: null,
      body: filters
    }
    return this.http.post(this.gatewayAPI + 'data-api', data, this.getHeader()).toPromise();
  }

  getRelatorioEntegaFeedback(filters = null){
    filters = this.getFiltersProjetoRequired(filters, this.state.selectedIdProjetoTurma);
    const data = {
      api: "relatorio-entrega-feedback",
      action: "datalist",
      normalize: false,
      params: null,
      body: filters
    }
    return this.http.post(this.gatewayAPI + 'data-api', data, this.getHeader()).toPromise();
  }

  getRelatorioProjetoForum(filters = null){
    filters = this.getFiltersProjetoRequired(filters, this.state.selectedIdProjetoTurma);
    const data = {
      api: "relatorio-projeto-forum",
      action: "datalist",
      normalize: false,
      params: null,
      body: filters
    }
    return this.http.post(this.gatewayAPI + 'data-api', data, this.getHeader()).toPromise();
  }

  getRelatorioAvaliacoesAndamento(filters = null){
    filters = this.getFiltersProjetoRequired(filters, this.state.selectedIdProjetoTurma);
    const data = {
      api: "relatorio-avaliacoes-andamento",
      action: "datalist",
      normalize: false,
      params: null,
      body: filters
    }
    return this.http.post(this.gatewayAPI + 'data-api', data, this.getHeader()).toPromise();
  }

  getRelatorioAvaliacoesModeloUnibr(filters = null){
    filters = this.getFiltersProjetoRequired(filters, this.state.selectedIdProjetoTurma);
    const data = {
      api: "relatorio-avaliacoes-modelo-unibr",
      action: "datalist",
      normalize: false,
      params: null,
      body: filters
    }
    return this.http.post(this.gatewayAPI + 'data-api', data, this.getHeader()).toPromise();
  }

  // ---------- Relatórios de empresa ------------

  getFiltersRelatorioEmpresa(filters = null){
    if (!filters || filters.filters.length == 0)
      filters = { filters: [{ field: 'empresa_id_empresa', value: this.state.selectedEmpresa.id_empresa }], pagination:[], sort:[] };
    else {
      filters.filters = filters.filters.map(filtro => ({
        field: filtro.field, value: filtro.value, type: filtro.field == 'empresa_id_empresa' ? 'select' : filtro.type
      }));
    }
    return filters
  }

  getRelatorioUsuariosSemProjeto(filters = null){
    const filter = this.getFiltersRelatorioEmpresa(filters)
    const data = {
      api: "relatorio-usuario-sem-projeto",
      action: "datalist",
      normalize: false,
      params: null,
      body: filter
    }
    return this.http.post(this.gatewayAPI + 'data-api', data, this.getHeader()).toPromise();
  }

  getRelatorioPerfilUsuario(filters = null){
    const filter = this.getFiltersRelatorioEmpresa(filters)
    const data = {
      api: "relatorio-perfil-usuario",
      action: "datalist",
      normalize: false,
      params: null,
      body: filter
    }
    return this.http.post(this.gatewayAPI + 'data-api', data, this.getHeader()).toPromise();
  }

  // --------------------------------------------------------------------------------------

  getForumDetails(id_forum){
    return this.http.get(this.gatewayAPI + 'forum/detalhes/' + id_forum, this.getHeader()).toPromise();
  }

  getParticipantesDetails(id_projeto_turma_estudante) {
    return this.http.get(this.gatewayAPI + 'participante/detalhes/' + id_projeto_turma_estudante, this.getHeader()).toPromise();
  }

  getAvaliacaoDetails(id_projeto_turma_estudante) {
    return this.http.get(this.gatewayAPI + 'avaliacao/' + id_projeto_turma_estudante, this.getHeader()).toPromise();
  }

  getTentativaRespondida(id_avaliacao_estudante: number, id_avaliacao_estudante_tentativa: number): Observable<any> {
    return this.http.get(this.gatewayAPI + 'avaliacao/' + id_avaliacao_estudante + '/tentativa/' + id_avaliacao_estudante_tentativa + '/revisao', this.getHeader());
  }

  desmatricularAluno(obj) {
    return this.http.post(this.gatewayAPI + 'projeto-turma-estudante/desmatricular', obj, this.getHeader()).toPromise();
  }

  createUser(user) {
    return this.http.post(this.gatewayAPI + 'usuarios', user, this.getHeader()).toPromise();
  }

  updateUser(user) {
    return this.http.patch(this.gatewayAPI + 'usuarios/' + user.id_usuario, user, this.getHeader()).toPromise();
  }

  getDadosUsuario(id_usuario, id_empresa) {
    return this.http.get(this.gatewayAPI + 'usuarios/' + id_usuario + '/' + id_empresa, this.getHeader()).toPromise();
  }

  resetUserPasswordToCpf(id) {
    return this.http.get(this.gatewayAPI + 'usuario/reset-password-to-cpf/' + id, this.getHeader()).toPromise();
  }

  changeUserPassword(credentials: Partial<{ oldPassword: string, newPassword: string, againpassword: string }>, idUsuario: number) {
    return this.http.post(this.gatewayAPI + 'usuario/' + idUsuario + '/changePassword/', credentials, this.getHeader()).toPromise();
  }

  updateEstudantes(idEstudante: number, data: any): Observable<any> {
    return this.http.patch(this.gatewayAPI + 'estudantes/' + idEstudante, data, this.getHeader());
  }

  getState() {
    this.stateSubscription = this.store.select((state: any) => {
      if (state.newAppState && state.newAppState.application && state.newAppState.application.token) {
        const { application, selectedEmpresa, selectedIdProjetoTurma } = state.newAppState;
        this.state = { application, selectedEmpresa, selectedIdProjetoTurma };
      }
    }).subscribe();
  }

  getHeader() {
    const header = {
      headers: new HttpHeaders()
        .set('Authorization', 'bearer ' + this.state.application.token)
    };
    return header;
  }

  // -----------------------------------------------------------------------------------------------------
  // Casos de Sucesso

  getCasosDeSucessoList(filters = null) {
    filters = this.getFiltersEmpresaRequired(filters)
    return this.http.post(this.gatewayAPI + 'caso-de-sucesso/datalist', filters, this.getHeader()).toPromise();
  }

  getDadosCasosDeSucesso(id) {
    return this.http.get(this.gatewayAPI + 'caso-de-sucesso/' + id, this.getHeader()).toPromise();
  }

  createCasosDeSucesso(data) {
    const headers = { 'Content-Type': 'multipart/form-data', ...this.getHeader() };
    return this.http.post(this.gatewayAPI + 'caso-de-sucesso/', data, headers).toPromise();
  }

  updateCasosDeSucesso(id, data) {
    const headers = { 'Content-Type': 'multipart/form-data', ...this.getHeader() };
    return this.http.patch(this.gatewayAPI + 'caso-de-sucesso/' + id, data, headers).toPromise();
  }

  saveCasosDeSucesso(id, data) {
    if (id) {
      return this.updateCasosDeSucesso(id, data);
    }
    return this.createCasosDeSucesso(data);
  }

  deleteCasosDeSucesso(id) {
    return this.http.delete(this.gatewayAPI + 'caso-de-sucesso/' + id, this.getHeader()).toPromise();
  }

  // ---------------------------------------------------------------------------------------------------------------------------
  // Soft Skills

  getSoftSkillsList(filters = null) {
    filters = this.getFiltersEmpresaRequired(filters)
    return this.http.post(this.gatewayAPI + 'softskills/datalist', filters, this.getHeader()).toPromise();
  }

  getDadosSoftSkills(id) {
    return this.http.get(this.gatewayAPI + 'softskills/' + id, this.getHeader()).toPromise();
  }

  createSoftSkills(data) {
    const headers = { 'Content-Type': 'multipart/form-data', ...this.getHeader() };
    return this.http.post(this.gatewayAPI + 'softskills/', data, headers).toPromise();
  }

  updateSoftSkills(id, data) {
    const headers = { 'Content-Type': 'multipart/form-data', ...this.getHeader() };
    return this.http.patch(this.gatewayAPI + 'softskills/' + id, data, headers).toPromise();
  }

  saveSoftSkills(id, data) {
    if (id) {
      return this.updateSoftSkills(id, data);
    }
    return this.createSoftSkills(data);
  }

  deleteSoftSkills(id) {
    return this.http.delete(this.gatewayAPI + 'softskills/' + id, this.getHeader()).toPromise();
  }

  createCoordenacao(obj) {
    return this.http.post(this.gatewayAPI + 'coordenacao/', obj, this.getHeader()).toPromise();
  }

  updateCoordenacao(obj) {
    return this.http.patch(this.gatewayAPI + 'coordenacao/' + obj.id_coordenacao, obj, this.getHeader()).toPromise();
  }

  deleteCoordenacao(id) {
    return this.http.delete(this.gatewayAPI + 'coordenacao/' + id, this.getHeader()).toPromise();
  }


  //---------------------------------------------------------------------------------------------------------------------------
  //Ofertas

  getOfertasList(filters = null) {
    filters = this.getFiltersEmpresaRequired(filters)
    const data = {
      api: "oferta",
      action: "datalist",
      normalize: false,
      params: null,
      body: filters,
    }
    return this.http.post(this.gatewayAPI + 'data-api', data, this.getHeader()).toPromise();
  }

  getOfertaCategoria(){
    const data = {
      service: "api",
      api: "oferta_categoria_catalogo",
      action: "index",
      normalize: false,
      body: {},
    }
    return this.http.post(this.gatewayAPI + 'data-api', data, this.getHeader()).toPromise();
  }

  getOfertaCupons(){
    const data = {
      service: "api",
      api: "empresa_cupom",
      action: "index",
      normalize: false,
      body: {
        "where": [
          {
              "field": "id_empresa",
              "operator": "=",
              "value": this.state.selectedEmpresa.id_empresa
          }
        ]
      },
    }
    return this.http.post(this.gatewayAPI + 'data-api', data, this.getHeader()).toPromise();
  }

  getDadosOferta(id) {
    const body = {
      "service": "api",
      "api": "oferta/" + id,
      "action": "index",
      "method": "post"
    }
    return this.http.post(this.gatewayAPI + 'data-api', body, this.getHeader()).toPromise();
  }

  createOferta(data){
    const headers = {​​ 'Content-Type': 'multipart/form-data', ...this.getHeader()}
    const body = {
      "service": "api",
      "api": "criar/oferta",
      "action": "store",
      "method": "post",
      "body": data
    }
    return this.http.post(this.gatewayAPI + 'data-api', body, headers).toPromise();
  }

  updateOferta(data) {
    const headers = {​​ 'Content-Type': 'multipart/form-data', ...this.getHeader()}
    const oferta = {...data};
    delete oferta.projetos;
    delete oferta.categorias;
    delete oferta.cupons;
    const body = {
      "service":"api",
      "api":"oferta/" + data.id_oferta + "/editar",
      "action":"store",
      "body": {
        "oferta": oferta,
        "projetos": data.projetos ? data.projetos : [],
        "cupons": data.cupons,
        "categorias": data.categorias ? data.categorias : []
      }
    }
    return this.http.post(this.gatewayAPI + 'data-api', body, headers).toPromise();
  }

  saveOferta(data) {
    if (data.id_oferta){
      return this.updateOferta(data)
    }
    return this.createOferta(data)
  }

  inativarOferta(data) {
    const headers = {​​ 'Content-Type': 'multipart/form-data', ...this.getHeader()}
    const oferta = { ativa: false, id_oferta: data.id_oferta };
    const cuponsTemp = [];
    data.ofertaCupom.forEach(element => {
      cuponsTemp.push(element.id_empresa_cupom)
    });
    const body = {
      "service":"api",
      "api":"oferta/" + data.id_oferta + "/editar",
      "action":"store",
      "body": {
        "oferta": oferta,
        "projetos": [],
        "cupons": cuponsTemp,
        "categorias": []
      }
    }
    return this.http.post(this.gatewayAPI + 'data-api', body, headers).toPromise();
  }

    // ---------------------------------------------------------------------------------------------------------------------------
  // CUPONS

  getCupomList(filters = null) {
    filters = this.getFiltersEmpresaRequired(filters);
    if(filters.filters.length == 1){
      filters.pagination = [];
      filters.sort = [];
    }
    const data = {
      api: "empresa_cupom",
      action: "datalist",
      normalize: false,
      params: null,
      body: filters,
    }
    return this.http.post(this.gatewayAPI + 'data-api', data, this.getHeader()).toPromise();
  }

  getDadosCupom(id) {
    const body = {
      "api": "empresa_cupom",
      "action": "show",
      "normalize": false,
      "params": { "id": id },
    }
    return this.http.post(this.gatewayAPI + 'data-api', body, this.getHeader()).toPromise();
  }

  createCupom(data) {
    delete data.id_empresa_cupom;
    const headers = {​​ 'Content-Type': 'multipart/form-data', ...this.getHeader()}
    const body = {
      "api": "empresa_cupom",
      "action": "store",
      "normalize": false,
      "params": null,
      "body": data
    }
    return this.http.post(this.gatewayAPI + 'data-api', body, headers).toPromise();
  }
  
  updateCupom(data) {
    const headers = {​​ 'Content-Type': 'multipart/form-data', ...this.getHeader()}
    const cupom = {...data};
    const body = {
      "api":"empresa_cupom",
      "action":"update",
      "normalize": false,
      "params": { "id": cupom.id_empresa_cupom },
      "body": {
          "nome": cupom.nome ? cupom.nome : [],
          "link": cupom.link ? cupom.link : [],
          "ativo": cupom.ativo
        }
      }
    return this.http.post(this.gatewayAPI + 'data-api', body, headers).toPromise();
  }
      
  saveCupom(data) {
    if (data.id_empresa_cupom){
      return this.updateCupom(data)
    }
    return this.createCupom(data)
  }

  deleteCupom(id) {
    const headers = {​​ 'Content-Type': 'multipart/form-data', ...this.getHeader()}
    const body = {
      "api":"empresa_cupom",
      "action":"delete",
      "normalize": false,
      "params": { "id": id },
      "body": { }
      }
    return this.http.post(this.gatewayAPI + 'data-api', body, headers).toPromise();
  }

     // ---------------------------------------------------------------------------------------------------------------------------
  // PERFIL TESTE

  getPerfilTestList(filters = null) {
    filters = this.getFiltersEmpresaRequired(filters);
    if(filters.filters.length == 1){
      filters.pagination = [];
      filters.sort = [];
    }
    const data = {
      api: "perfil-teste",
      action: "datalist",
      normalize: false,
      params: null,
      body: filters,
    }
    return this.http.post(this.gatewayAPI + 'data-api', data, this.getHeader()).toPromise();
  }

  getDadosPerfilTeste(id) {
    const body = {
      "api": "perfil-teste",
      "action": "show",
      "normalize": false,
      "params": { "id": id },
    }
    return this.http.post(this.gatewayAPI + 'data-api', body, this.getHeader()).toPromise();
  }

  createPerfilTeste(data) {
    const headers = {​​ 'Content-Type': 'multipart/form-data', ...this.getHeader()}
    const body = {
      "api": "perfil-teste",
      "action": "store",
      "normalize": false,
      "params": null,
      "body": data
    }
    return this.http.post(this.gatewayAPI + 'data-api', body, headers).toPromise();
  }
  
  updatePerfilTeste(data) {
    const headers = {​​ 'Content-Type': 'multipart/form-data', ...this.getHeader()}
    const body = {
      "api": "perfil-teste",
      "action": "update",
      "normalize": false,
      "params": { "id": data.id_perfil_teste },
      "body": data
    }
    return this.http.post(this.gatewayAPI + 'data-api', body, headers).toPromise();
  }
      
  savePerfilTeste(data) {
    if (data.id_perfil_teste){
      return this.updatePerfilTeste(data)
    }
    return this.createPerfilTeste(data)
  }

  deletePerfilTeste(id){
    const headers = {​​ 'Content-Type': 'multipart/form-data', ...this.getHeader()}
    const body = {
      "api":"perfil-teste",
      "action":"delete",
      "normalize": false,
      "params": { "id": id },
      "body": { }
      }
    return this.http.post(this.gatewayAPI + 'data-api', body, headers).toPromise();
  }

  // ---------------------------------------------------------------------------------------------------------------------------
  // PERFIL CATALOGO

  getPerfilCatalogoList(id_perfil_teste) {
    const data = {
      api: "perfil-catalogo",
      action: "index",
      normalize: false,
      params: null,
      body:{
        "where": [
          {
            "field": "id_perfil_teste",
            "operator": "=",
            "value": id_perfil_teste
          }
        ]
      }
    }
    return this.http.post(this.gatewayAPI + 'data-api', data, this.getHeader()).toPromise();
  }

  savePerfilCatalogo(data) {
    const headers = { 'Content-Type': 'multipart/form-data', ...this.getHeader() }
    return this.http.post(this.gatewayAPI + 'custom-api', data, headers).toPromise();
  }

  deletePerfilCatalogo(id){
    const headers = {​​ 'Content-Type': 'multipart/form-data', ...this.getHeader()}
    const body = {
      "api":"perfil-catalogo",
      "action":"delete",
      "normalize": false,
      "params": { "id": id },
      "body": { }
      }
    return this.http.post(this.gatewayAPI + 'data-api', body, headers).toPromise();
  }

  // ---------------------------------------------------------------------------------------------------------------------------
  // PERFIL QUESTÕES

  getPerfilTesteQuestaoList(id_perfil_teste) {
    const data = {
      api: "perfil-teste-questao",
      action: "index",
      normalize: false,
      params: null,
      body:{
        "where": [
          {
            "field": "id_perfil_teste",
            "operator": "=",
            "value": id_perfil_teste
          }
        ]
      }
    }
    return this.http.post(this.gatewayAPI + 'data-api', data, this.getHeader()).toPromise();
  }

  createPerfilTesteQuestao(data) {
    const headers = {​​ 'Content-Type': 'multipart/form-data', ...this.getHeader()}
    const body = {
      "api": "perfil-teste-questao",
      "action": "store",
      "normalize": false,
      "params": null,
      "body": data
      }
    return this.http.post(this.gatewayAPI + 'data-api', body, headers).toPromise();
  }
  
  updatePerfilTesteQuestao(data) {
    const headers = {​​ 'Content-Type': 'multipart/form-data', ...this.getHeader()}
    const body = {
      "api": "perfil-teste-questao",
      "action": "update",
      "normalize": false,
      "params": { "id": data.id_perfil_teste_questao },
      "body": data
    }
    return this.http.post(this.gatewayAPI + 'data-api', body, headers).toPromise();
  }

  savePerfilTesteQuestao(data) {
    if (data.id_perfil_teste_questao){
      return this.updatePerfilTesteQuestao(data)
    }
    return this.createPerfilTesteQuestao(data)
  }

  deletePerfilTesteQuestao(id){
    const headers = {​​ 'Content-Type': 'multipart/form-data', ...this.getHeader()}
    const body = {
      "api":"perfil-teste-questao",
      "action":"delete",
      "normalize": false,
      "params": { "id": id },
      "body": { }
      }
    return this.http.post(this.gatewayAPI + 'data-api', body, headers).toPromise();
  }

}
