import {Component, Input } from '@angular/core';
import {NotificacaoMensagemModel} from '../../../../../../src/app/models/notificacao-mensagem.model';

@Component({
  selector: 'ui-mensagem-list',
  templateUrl: './mensagem-list.component.html',
  styleUrls: ['./mensagem-list.component.scss']
})
export class MensagemListComponent {
  @Input() mensagem: NotificacaoMensagemModel;
  @Input() ultimaMensagem: boolean;
  @Input() mensagemLida: boolean;
}
