import { Injectable } from '@angular/core';
import { AppState } from 'src/app/_state';
import { Store } from "@ngrx/store";

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  stateSubscription: any;
  state: any;

  constructor(private store: Store<AppState>) {
    this.getState();
  }

  can(permission){
    let decision = false;
    this.state.selectedEmpresa.papeis.forEach(papelID => {
      if (permission.includes(this.state.papeis.byId[papelID].papel))
        decision = true;
    });
    return decision;
  }

  possuiCapacidades(idEmpresa: number, capacidade: string) {
    for (const id in this.state.papeis.byId) {
      const empresa = this.state.papeis.byId[id];
      if (empresa.id_empresa === idEmpresa) {
        const eIncluido = empresa.capacidades.includes(capacidade);
        if (eIncluido) {
          return true;
        }
      }
    }
    return false;
  }

  getState() {
    this.stateSubscription = this.store.select((state: any) => {
      const { papeis, selectedEmpresa } = state.newAppState;
      this.state = { papeis, selectedEmpresa };
    }).subscribe();
  }
}
