import { AppState } from './index';
import {
  fillNewProject,
  authSuccess,
  doLogin,
  authFailed,
  fillProjectData,
  fillIdProjetoTurma,
  fillSelectedTurmaID,
  fillProjectDetails,
  fillFasesTurma,
  toggleAsideAlunos,
  fillSelectedUsuarioID,
  fillSelectedFaseTurmaID,
  setAsideAlunos,
  fillCurrentUser,
  fillCurrentProjeto,
  showAdminUserEdition,
  editionUser,
  fillSuportModal,
  fillForumModal,
  fillParticipantesModal,
  fillConfirmModal,
  fillAvaliacaoModal,
  fillCoordenacaoModal,
  showAdminCasosDeSucessoEdition,
  showAdminSoftSkillsEdition,
  showAdminOfertaEdition,
  fillEditionOferta,
  showAdminCupomEdition,
  fillEditionCupom,
  fillEditionCasosDeSucesso,
  fillEditionSoftSkills,
  fillDeleteTentativa,
  notificacoes,
  showMenu,
  showNotificacoes,
  showUsuarioPicker,
  fillUsuarioPicked,
  fillCoordenadoresModal,
  fillDeletedProjectID,
  clearState,
  showModalAddUsuarioParticipantes,
  updateProjects,
  empresaSelecionada,
  fillDadosNotificacao,
  fillDadosPerfilTeste,
  showPerfilTesteEdition,
  fillDadosPerfilCatalogo,
  showCatalogoModal,
} from './state.actions';
import { createSelector } from '@ngrx/store';
import { CasoDeSucesso } from 'src/app/models/caso-de-sucesso.model';
import { SoftSkill } from '../models/softskill.model';
import { Oferta } from '../models/oferta.model';
import { Cupom } from '../models/cupom.model';

export const initialState: AppState = {
    auth: null,
    selectedEmpresa: null,
    currentUser: null,
    currentProjeto: null,
    fasesTurma: {},
    marcos: [],
    usuarios: [],
    projetos: [],
    tutores: [],
    empresas: [],
    papeis: [],
    turmas: [],
    cursos: [],
    application: {
        loading: false,
        token: null,
        asideAlunosOpened: false
    },
    selectedIdProjetoTurma: null,
    selectedIdUsuario: null,
    selectedIdFaseTurma: null,
    projetoDetails: {},
    showAdminCasosDeSucessoEdition: false,
    showAdminSoftSkillsEdition: false,
    showAdminCupomEdition: false,
    editionCupom: new Cupom(),
    showAdminOfertaEdition: false,
    editionOferta: new Oferta(),
    editionCasosDeSucesso: new CasoDeSucesso(),
    editionSoftSkills: new SoftSkill(),
    avaliacaoModal: null,
    notificacoes: null,
    showMenu: false,
    showNotificacoes: false,
    showModalAddUsuarioParticipantes: false,
    showPerfilTesteEdition: false,
    perfilTeste: null,
    perfilCatalogo: null,
    showCatalogoModal: false,
};

export function reducer(state: AppState, action: any) {
    switch (action.type) {
        case toggleAsideAlunos.type:
            return {
                ...state,
                application: {
                    ...state.application,
                    asideAlunosOpened: !state.application.asideAlunosOpened
                }
            };
        case setAsideAlunos.type:

            return {
                ...state,
                application: {
                    ...state.application,
                    asideAlunosOpened: action.payload
                }
            };
        case authSuccess.type:
            let selectedEmpresaTemp = null;
            let token = null;
            let module = null;

            if (action.payload.auth) {
                sessionStorage.setItem('id_usuario', action.payload.usuario.id_usuario);
                sessionStorage.setItem('token', action.payload.auth.token);
                token = action.payload.auth.token;
                sessionStorage.setItem('id_empresa', action.payload.selectedEmpresaID);
            } else{
                token = action.payload.token;
            }

            let empresasTemp = [];
            action.payload.empresas.allIds.forEach(id => {
                let isAluno = true;
                action.payload.empresas.byId[id].papeis.forEach(id_papel => {
                    if (action.payload.papeis.byId[id_papel].papel.toLowerCase() != 'aluno')
                        isAluno = false;
                });
                if (!isAluno)
                    empresasTemp.push(action.payload.empresas.byId[id]);
            });

            if (empresasTemp.length === 1)
                selectedEmpresaTemp = empresasTemp[0];
            else if (action.payload.empresas.allIds.length > 0 && action.payload.selectedEmpresaID)
                selectedEmpresaTemp = action.payload.empresas.byId[action.payload.selectedEmpresaID];

            return {
                ...state,
                selectedEmpresa: selectedEmpresaTemp,
                empresas: action.payload.empresas,
                usuario: action.payload.usuario,
                papeis: action.payload.papeis,
                application: {
                    token: token
                }
            };
        case doLogin.type:
            break;
        case authFailed.type:
            break;
        case fillProjectData.type:
            return {
                ...state,
                projetos: action.payload.projetos,
                turmas: action.payload.turmas
            };
        case fillNewProject.type:
            return {
                ...state,
                newProject: action.payload
            };
        case fillDeletedProjectID.type: {
            return {
                ...state,
                deletedProjectID: action.payload
            }
        }
        case fillDadosNotificacao.type: {
            return {
                ...state,
                dadosNotificacao: action.payload
            }
        }
        case updateProjects.type:{
            return {
                ...state,
                projetos: action.payload
            }
        }
        case fillIdProjetoTurma.type:
            return {
                ...state,
                selectedIdProjetoTurma: action.payload,
            };
        case fillSelectedTurmaID.type:
            return {
                ...state,
                selectedTurmaID: action.payload
            };
        case fillProjectDetails.type:
            return {
                ...state,
                projetoDetails: action.payload
            };
        case fillFasesTurma.type:
            const oldFaseTurma = state.fasesTurma || { allIds: [], byId: {} };

            const newFaseTurma = action.payload || { allIds: [], byId: {} };
            return {
                ...state,

                fasesTurma: {
                    allIds: [...new Set([...oldFaseTurma.allIds, ...newFaseTurma.allIds])],
                    byId: { ...oldFaseTurma.byId, ...newFaseTurma.byId }
                }
            };
        case fillSelectedUsuarioID.type:
            return {
                ...state,
                selectedIdUsuario: action.payload
            };
        case fillSelectedFaseTurmaID.type:
            return {
                ...state,
                selectedIdFaseTurma: action.payload
            };
        case fillCurrentUser.type:
            return {
                ...state,
                currentUser: action.payload,
                usuario: action.payload
            };
        case fillCurrentProjeto.type:
            return {
                ...state,
                currentProjeto: action.payload
            };
        case showAdminUserEdition.type:
            return {
                ...state,
                showAdminUserEdition: action.payload
            };
        case editionUser.type:
            return {
                ...state,
                editionUser: action.payload
            };
        case fillSuportModal.type:
            return {
                ...state,
                suportModal: action.payload
            };
        case fillForumModal.type:
            return {
                ...state,
                forumModal: action.payload
            };
        case fillParticipantesModal.type:
            return {
                ...state,
                participantesModal: action.payload
            };
        case fillAvaliacaoModal.type:
            return {
                ...state,
                avaliacaoModal: action.payload
            };
        case fillCoordenacaoModal.type:
            return {
                ...state,
                coordenacaoModal: action.payload
            };
        case fillCoordenadoresModal.type:
            return {
                ...state,
                coordenadoresModal: action.payload
            };
        case showUsuarioPicker.type:
            return {
                ...state,
                showUsuarioPicker: action.payload
            };
        case fillUsuarioPicked.type:
            return {
                ...state,
                usuarioPicked: action.payload
            };
        case clearState.type:
            return {}
        case fillConfirmModal.type:
            return {
                ...state,
                confirmModal: action.payload
            };
        case fillDeleteTentativa.type:
            return {
                ...state,
                avaliacaoModal: {
                    avaliacaoEstudante: {
                        ...state.avaliacaoModal.avaliacaoEstudante,
                        tentativa: [...state.avaliacaoModal.avaliacaoEstudante.tentativa.filter(item => action.payload !== item)]
                    }
                }
            };
        case fillEditionCasosDeSucesso.type:
            return {
                ...state,
                editionCasosDeSucesso: action.payload
            };
        case fillEditionSoftSkills.type:
            return {
                ...state,
                editionSoftSkills: action.payload
            };
        case fillEditionOferta.type:
            return {
                ...state,
                editionOferta: action.payload
            };
        case fillEditionCupom.type:
            return {
                ...state,
                editionCupom: action.payload
            };
        case showAdminCupomEdition.type:
            return {
                ...state,
                showAdminCupomEdition: action.payload
            };
        case fillDadosPerfilTeste.type:
            return {
                ...state,
                perfilTeste: action.payload
            };
        case showPerfilTesteEdition.type:
            return {
                ...state,
                showPerfilTesteEdition: action.payload
            };
        case fillDadosPerfilCatalogo.type:
            return {
                ...state,
                perfilCatalogo: action.payload
            };
        case showCatalogoModal.type:
            return {
                ...state,
                showCatalogoModal: action.payload
            };
        case showAdminSoftSkillsEdition.type:
            return {
                ...state,
                showAdminSoftSkillsEdition: action.payload
            };
        case showAdminCasosDeSucessoEdition.type:
            return {
                ...state,
                showAdminCasosDeSucessoEdition: action.payload
            };
        case showAdminOfertaEdition.type:
            return {
                ...state,
                showAdminOfertaEdition: action.payload
            };
        case notificacoes.type:
            return {
                ...state,
                notificacoes: action.payload
            };
        case showMenu.type:
            return {
                ...state,
                showMenu: action.payload
            };
        case showNotificacoes.type:
            return {
                ...state,
                showNotificacoes: action.payload
            };
        case showModalAddUsuarioParticipantes.type:
          return {
            ...state,
            showModalAddUsuarioParticipantes: action.payload
          };
        case empresaSelecionada.type:
          if('papeis' in action.payload)
            return {
              ...state,
              selectedEmpresa: {...action.payload}
            };

          const oldEmpresa = state.selectedEmpresa;
          return {
            ...state,
            selectedEmpresa: {...action.payload, papeis: oldEmpresa.papeis}
          };

        default:
            return state;
    }
}

// export const getApplication = (state: AppState) => state.application

export const selectApplication = createSelector(
    (state: any) => state.newAppState.application.token,
    token => token
);
