import { createAction, props } from '@ngrx/store';
import { Auth } from '../models/auth.model';
import { ActionTypes } from '../models/enums/action-types.enum';
import { FaseTurma } from '../models/fase-turma.model';
import { Usuario } from '../models/usuario.model';
import {TentativaModel} from '../modules/turmas/models/avaliacao-estudante.model';
import {NotificacaoMensagemTratadaModel} from '../models/notificacao-mensagem.model';
import {Empresa} from '../models/empresa.model';

export const doLogin = createAction(ActionTypes.DO_LOGIN, props<{ payload: Auth }>());
export const authSuccess = createAction(ActionTypes.LOGIN_SUCCESS, props<{ payload: any }>());
export const authFailed = createAction(ActionTypes.LOGIN_FAILED, props<{payload: string}>());
export const empresaSelecionada = createAction(ActionTypes.EMPRESA_SELECIONADA, props<{payload: Empresa}>());
export const fillProjectData = createAction(ActionTypes.FILL_PROJECT_DATA, props<{payload: any}>());
export const fillNewProject = createAction(ActionTypes.FILL_NEW_PROJECT, props<{payload: any}>());
export const fillDeletedProjectID = createAction(ActionTypes.FILL_DELETED_PROJECT_ID, props<{payload: any}>());
export const updateProjects = createAction(ActionTypes.UPDATE_PROJECTS, props<{payload: any}>());
export const fillIdProjetoTurma = createAction(ActionTypes.FILL_ID_PROJETO_TURMA, props<{payload: any}>());
export const fillTurmasData = createAction(ActionTypes.FILL_TURMAS_DATA, props<{payload: any}>());
export const fillSelectedTurmaID = createAction(ActionTypes.FILL_SELECTED_TURMA_ID, props<{payload: any}>());
export const fillProjectDetails = createAction(ActionTypes.FILL_PROJECT_DETAIL, props<{payload: any}>());
export const fillFasesTurma = createAction(ActionTypes.FILL_FASES_TURMA, props<{payload: Array<FaseTurma>}>());
export const toggleAsideAlunos = createAction(ActionTypes.TOGGLE_ASIDE_ALUNOS);
export const setAsideAlunos = createAction(ActionTypes.SET_ASIDE_ALUNOS, props<{payload: boolean}>());
export const fillSelectedUsuarioID = createAction(ActionTypes.FILL_SELECTED_USUARIO_ID, props<{payload: number}>());
export const fillSelectedFaseTurmaID = createAction(ActionTypes.FILL_SELECTED_FASE_TURMA_ID, props<{payload: number}>());
export const fillCurrentUser = createAction(ActionTypes.FILL_CURRENT_USER, props<{payload: Usuario}>());
export const fillCurrentProjeto = createAction(ActionTypes.FILL_CURRENT_PROJETO, props<{payload: any}>());
export const showAdminUserEdition = createAction(ActionTypes.SHOW_ADMIN_USER_EDITION, props<{payload: boolean}>());
export const editionUser = createAction(ActionTypes.EDITION_USER, props<{payload: any}>());
export const fillDeleteTentativa = createAction(ActionTypes.FILL_DELETE_TENTATIVA, props<{payload: TentativaModel}>());
export const showUsuarioPicker = createAction(ActionTypes.SHOW_USUARIO_PICKER, props<{payload: boolean}>());
export const fillUsuarioPicked = createAction(ActionTypes.FILL_USUARIO_PICKED, props<{payload: any}>());
export const clearState = createAction(ActionTypes.CLEAR_STATE);

export const fillEditionCasosDeSucesso = createAction(ActionTypes.FILL_EDITION_CASOS_DE_SUCESSO, props<{payload: any}>());
export const fillEditionSoftSkills = createAction(ActionTypes.FILL_EDITION_SOFT_SKILLS, props<{payload: any}>());
export const fillEditionOferta = createAction(ActionTypes.FILL_EDITION_OFERTA, props<{payload: any}>());
export const showAdminOfertaEdition = createAction(ActionTypes.SHOW_ADMIN_OFERTA_EDITION, props<{ payload: boolean }>());
export const showAdminCasosDeSucessoEdition = createAction(ActionTypes.SHOW_ADMIN_CASOS_DE_SUCESSO_EDITION, props<{payload: boolean}>());
export const showAdminSoftSkillsEdition = createAction(ActionTypes.SHOW_ADMIN_SOFT_SKILLS_EDITION, props<{payload: boolean}>());
export const notificacoes = createAction(ActionTypes.NOTIFICACOES, props<{payload: NotificacaoMensagemTratadaModel}>());
export const showMenu = createAction(ActionTypes.SHOWMENU, props<{payload: boolean}>());
export const showNotificacoes = createAction(ActionTypes.SHOWNOTIFICACOES, props<{payload: boolean}>());
export const fillEditionCupom = createAction(ActionTypes.FILL_EDITION_CUPOM, props<{payload: any}>());
export const showAdminCupomEdition = createAction(ActionTypes.SHOW_ADMIN_CUPOM_EDITION, props<{ payload: boolean }>());

// Modal
export const fillSuportModal = createAction(ActionTypes.FILL_SUPORT_MODAL, props<{payload: any}>());
export const fillForumModal = createAction(ActionTypes.FILL_FORUM_MODAL, props<{payload: any}>());
export const fillParticipantesModal = createAction(ActionTypes.FILL_PARTICIPANTES_MODAL, props<{payload: any}>());
export const fillConfirmModal = createAction(ActionTypes.FILL_CONFIRM_MODAL, props<{payload: any}>());
export const fillAvaliacaoModal = createAction(ActionTypes.FILL_AVALIACAO_MODAL, props<{payload: any}>());
export const fillCoordenacaoModal = createAction(ActionTypes.FILL_COORDENACAO_MODAL, props<{payload: any}>());
export const fillCoordenadoresModal = createAction(ActionTypes.FILL_COORDENADORES_MODAL, props<{payload: any}>());
export const showModalAddUsuarioParticipantes = createAction(ActionTypes.SHOW_MODAL_ADD_USUARIO_PARTICIPANTES, props<{payload: any}>());
export const fillDadosNotificacao = createAction(ActionTypes.FILL_DADOS_NOTIFICACAO, props<{payload: any}>());

// Teste de Perfil
export const fillDadosPerfilTeste = createAction(ActionTypes.FILL_DADOS_PERFIL_TESTE, props<{payload: any}>());
export const showPerfilTesteEdition = createAction(ActionTypes.SHOW_PERFIL_TESTE_EDITION, props<{payload: boolean}>());
// Perfil Catalogo
export const fillDadosPerfilCatalogo = createAction(ActionTypes.FILL_DADOS_PERFIL_CATALOGO, props<{payload: any}>());
export const showCatalogoModal = createAction(ActionTypes.SHOW_CATALOGO_MODAL, props<{payload: boolean}>());
